



















import Vue from "vue";
import SocialMedia from "@/components/SocialMedia.vue";

export default Vue.extend({
  components: { SocialMedia }
});
